<template>
  <el-dialog
    :visible.sync="visible"
    title="监测MAC地址"
    width="1080px"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-table :data="macInfoList" class="yt-table" height="calc(100% - 34px)">
      <el-table-column label="姓名" prop="userName" show-overflow-tooltip align="center" />
      <el-table-column label="用户号" prop="jobNumber" show-overflow-tooltip align="center" />
      <el-table-column label="切换时间" show-overflow-tooltip>
        <template slot-scope="scope">{{ $formatTime(scope.row.submitTime, 'yyyy-MM-dd hh:mm') }}</template>
      </el-table-column>
      <el-table-column label="操作" show-overflow-tooltip>
        <div slot-scope="scope" :class="scope.row.status === 1 ? 'wait' : 'finish'" @click="choose(scope.row)">
          {{ scope.row.status === 1 ? '待确认' : '已确认' }}
        </div>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import examMonitorApi from '@api/examMonitor'
export default {
  name: 'monitorMacDialog',
  data() {
    return {
      visible: false,
      macInfoList: [],
      examId: null
    }
  },
  methods: {
    open(data) {
      this.examId = data
      this.getMacUserDetails()
      this.visible = true
    },
    close(done) {
      done()
    },
    choose(obj) {
      if (obj.status === 1) {
        examMonitorApi.checkChangeMacAddress(obj.ipRecordId).then(res => {
          this.getMacUserDetails()
        })
      }
    },
    getMacUserDetails() {
      examMonitorApi.getMacUserDetails(this.examId).then(res => {
        this.macInfoList = res.res
        this.$forceUpdate()
      })
    },
    closed() {
      this.visible = false
    }
  }
}
</script>

<style scoped lang="less">
.wait {
  cursor: pointer;
  color: #ff5050;
  font-weight: bold;
}

.finish {
  cursor: pointer;
  color: #31d2a8;
  font-weight: bold;
}
</style>
