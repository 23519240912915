import config from '../config/config'
import { axios } from './index'

const examMonitorUrl = config.baseUrl + '/exam/api/v1/monitor'

export default {
  //获取考试完整信息
  getDetail(examId) {
    return axios.get(`${examMonitorUrl}/detail/?examId=${examId}`)
  },
  //搜索评测监控列表
  searchExams(payload, pageNum, pageSize) {
    return axios.post(`${examMonitorUrl}/search/exams?page=${pageNum}&size=${pageSize}`, payload)
  },
  //增量获取正在考试的考试用户信息
  getOngoingUserInfo(examId, answerPaperId) {
    return axios.get(`${examMonitorUrl}/ongoing/user/info?examId=${examId}${answerPaperId ? '&answerPaperId=' + answerPaperId : ''}`)
  },
  //增量获取已提交的考试用户信息
  getSubmittedUserInfo(examId, lastAnswerPaperId) {
    return axios.get(`${examMonitorUrl}/submitted/user/info?examId=${examId}&lastAnswerPaperId=${lastAnswerPaperId}`)
  },
  //监测mac地址是否切换
  checkMacAddress(examId) {
    return axios.get(`${examMonitorUrl}/judge/change/address/mac?examId=${examId}`)
  },
  //获取mac地址变换列表
  getMacUserDetails(examId) {
    return axios.get(`${examMonitorUrl}/check/change/address/mac?examId=${examId}`)
  },
  //审查mac地址切换用户
  checkChangeMacAddress(ipRecordId) {
    return axios.post(`${examMonitorUrl}/audit/change/address/mac?ipRecordId=${ipRecordId}`)
  },
  //获取未参加考试的人员信息
  getUnParticipateUserList(examId) {
    return axios.get(`${examMonitorUrl}/get/un-participate/users?examId=${examId}`)
  }
}
