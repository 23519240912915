<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>测评监控</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/manage/exam/monitor' }">测评监控列表</el-breadcrumb-item>
        <el-breadcrumb-item>{{ examDetail.examName }}监控详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="monitor-info">
      <div class="yt-monitor-header">
        <div class="exam-info">
          <el-row>
            <el-col :span="20">
              <div class="exam-title">考试信息</div>
            </el-col>
            <el-col :span="4" style="padding: 7px">
              <el-input v-model="keyword" placeholder="通过姓名模糊搜索" class="yt-search" @keydown.enter.native="searchKeyword" style="height: 32px">
                <i slot="suffix" class="el-input__icon el-icon-search" @click="searchKeyword"></i>
              </el-input>
            </el-col>
          </el-row>
        </div>
        <div class="info">
          <el-row>
            <el-col :span="8" style="margin-bottom: 24px">
              <span class="exam-detail">考试名称：</span>
              <span class="detail">{{ examDetail.examName }}</span>
            </el-col>
            <el-col :span="8" style="margin-bottom: 24px">
              <span class="exam-detail">考试日期：</span>
              <span class="detail">{{ examDetail.examDate }}</span></el-col
            >
            <el-col :span="8" style="margin-bottom: 24px">
              <span class="exam-detail">考试时长：</span>
              <span class="detail">{{ examDetail.consumingTime }}</span></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="8">
              <span class="exam-detail">已提交人数：</span>
              <span class="submit submitted ">{{ examDetail.submitNum }}</span></el-col
            >
            <el-col :span="8">
              <span class="exam-detail">未提交人数：</span>
              <span class="submit uncommitted ">{{ examDetail.unSubmitNum }}</span></el-col
            >
            <el-col :span="6" style="margin-left:-20px">
              <el-tooltip class="item" effect="dark" content="点击查看未参加考试人员信息" placement="top-start">
                <YTIcon style="color: #999999;margin-right: 4px;font-size: 14px" :href="'#icon-tishi3'" />
              </el-tooltip>
              <el-popover placement="bottom" trigger="click">
                <el-table :data="unParticipateUserList" class="yt-table">
                  <el-table-column width="150" property="userName" label="姓名"></el-table-column>
                  <el-table-column width="150" property="jobNumber" label="用户号"></el-table-column>
                  <el-table-column width="150" property="phone" label="手机号"></el-table-column>
                </el-table>
                <span class="exam-detail" slot="reference" style="cursor: pointer">未参加人数：</span>
              </el-popover>
              <span class="submit join ">{{ examDetail.unParticipateNum }}</span>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="yt-container">
        <div class="yt-content">
          <div class="exam-info">
            <el-row>
              <el-col :span="19">
                <div class="exam-title">考试中人员</div>
              </el-col>
              <el-col :span="5">
                <div class="warning" v-show="isChange"></div>
                <div class="message" @click="seeExamMacChange">监测MAC地址</div>
              </el-col>
            </el-row>
          </div>
          <el-table :data="ongoingList" class="yt-table" height="calc(100% - 34px)">
            <el-table-column label="姓名" prop="userName" show-overflow-tooltip align="center" />
            <el-table-column label="用户号" prop="jobNumber" show-overflow-tooltip />
            <el-table-column label="开始时间" show-overflow-tooltip>
              <template slot-scope="scope">{{ $formatTime(scope.row.startTime, 'yyyy-MM-dd hh:mm') }}</template>
            </el-table-column>
            <div slot="empty" id="empty-slot-underway">
              <template v-show="!ongoingLoading">
                <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px"></YTIcon>
              </template>
            </div>
          </el-table>
        </div>
        <div class="yt-content">
          <div class="exam-info">
            <el-row>
              <el-col :span="20">
                <div class="exam-title">已提交人员</div>
              </el-col>
            </el-row>
          </div>
          <el-table :data="submittedList" class="yt-table" height="calc(100% - 34px)">
            <el-table-column label="姓名" prop="userName" show-overflow-tooltip align="center" />
            <el-table-column label="用户号" prop="jobNumber" show-overflow-tooltip />
            <el-table-column label="提交时间" show-overflow-tooltip>
              <template slot-scope="scope">{{ $formatTime(scope.row.submitTime, 'yyyy-MM-dd hh:mm') }}</template>
            </el-table-column>
            <div slot="empty" id="empty-slot-submitted">
              <template v-show="!submittedLoading">
                <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px"></YTIcon>
              </template>
            </div>
          </el-table>
        </div>
      </div>
    </div>
    <monitorMacDialog ref="monitorMac"></monitorMacDialog>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import examMonitorApi from '@api/examMonitor'
import monitorMacDialog from '@components/common/dialog/monitorMacDialog'
export default {
  name: 'MonitorDetail',
  components: { YTIcon, monitorMacDialog },
  data() {
    return {
      examId: '',
      examDetail: {
        examName: '',
        examDate: '',
        consumingTime: '',
        submitNum: 0,
        unSubmitNum: 0
      },
      keyword: '',
      ongoingList: [],
      submittedList: [],
      ongoingListCopy: [],
      submittedListCopy: [],
      ongoingLoading: true,
      submittedLoading: true,
      timer: null,
      isChange: false,
      macDate: [],
      unParticipateUserList: []
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.examId = vm.$handleParams('monitorDetailData').examId
      examMonitorApi.getDetail(vm.examId).then(res => {
        vm.examDetail = res.res
      })
    })
  },
  mounted() {
    this.refreshUserInfo()
    this.$refs.btn.onclick = this.getMacUserDetails()
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    seeExamMacChange() {
      this.$refs.monitorMac.open(this.examId)
    },
    refreshUserInfo() {
      this.getOngoingUserInfo()
      //定时器 没有查询的情况下3s刷新一次数据
      this.timer = setInterval(() => {
        this.getOngoingUserInfo()
        this.getUnParticipateUserList()
        this.checkMacAddress()
      }, 4000)
    },
    getUnParticipateUserList() {
      examMonitorApi.getUnParticipateUserList(this.examId).then(res => {
        this.unParticipateUserList = res.res
        this.$set(this.examDetail, 'unParticipateNum', this.unParticipateUserList.length)
      })
    },
    getOngoingUserInfo() {
      examMonitorApi
        .getOngoingUserInfo(this.examId, this.ongoingList.length > 0 ? this.ongoingList[0].answerPaperId : null)
        .then(res => {
          this.ongoingList = res.res.concat(this.ongoingList)
          this.ongoingListCopy = this.ongoingList
          this.$forceUpdate()
        })
        .finally(() => {
          this.ongoingLoading = false
          this.getSubmittedUserInfo()
        })
    },
    getSubmittedUserInfo() {
      examMonitorApi
        .getSubmittedUserInfo(this.examId, this.submittedList.length > 0 ? this.submittedList[0].answerPaperId : '')
        .then(res => {
          res.res.forEach(item => {
            this.ongoingList.map((data, index) => {
              if (data.answerPaperId === item.answerPaperId) {
                this.ongoingList.splice(index, 1)
              }
            })
          })
          this.submittedList = res.res.concat(this.submittedList)
          this.submittedListCopy = this.submittedList
          this.$set(this.examDetail, 'submitNum', this.submittedList.length)
          this.$set(this.examDetail, 'unSubmitNum', this.ongoingList.length)
          this.$forceUpdate()
        })
        .finally(() => {
          this.submittedLoading = false
        })
    },
    checkMacAddress() {
      examMonitorApi.checkMacAddress(this.examId).then(res => {
        this.isChange = res.res
      })
    },
    getMacUserDetails() {
      examMonitorApi.getMacUserDetails(this.examId).then(res => {
        this.macDate = res.res
        this.$forceUpdate()
      })
    },
    choose(obj) {
      if (obj.status === 1) {
        examMonitorApi.checkChangeMacAddress(obj.ipRecordId).then(res => {
          this.getMacUserDetails()
        })
      }
    },
    searchKeyword() {
      if (this.keyword === '') {
        this.ongoingList = []
        this.submittedList = []
        this.refreshUserInfo()
        return
      }
      clearInterval(this.timer)
      this.timer = null
      this.ongoingList = this.ongoingListCopy.filter(item => {
        if (item.userName.includes(this.keyword)) {
          return item
        }
      })
      this.submittedList = this.submittedListCopy.filter(item => {
        if (item.userName.includes(this.keyword)) {
          return item
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../theme/variables';

.monitor-info {
  height: 100%;
  background-color: #f8f8f8;
  .yt-monitor-header {
    margin: 0 10px 10px 10px;
    height: 154px;
    border-radius: 0 0 2px 2px;
    .info {
      width: 100%;
      height: 108px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      padding: 20px 40px 24px 40px;
      .exam-detail {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 24px;
        color: #666666;
      }
      .detail {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
      }
      .submit {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 28px;
      }
      .submitted {
        color: #51d351;
      }
      .uncommitted {
        color: #ff5050;
      }
      .join {
        color: #438bff;
      }
    }
  }
  .yt-container {
    display: flex;
    padding: 0 10px 10px 10px;
    width: 100%;
    height: calc(100% - 164px);
    font-size: @default;
    .yt-content {
      width: calc(100% - 20px / 2);
      height: 100%;
      padding: 0 0 10px 0;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      border-radius: 0 0 2px 2px;
      &:last-child {
        margin-left: 10px;
      }
      .title {
        color: #438bff;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
  }
  .exam-info {
    width: 100%;
    height: 46px;
    background: #e4e9ef;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2) !important;
    border-radius: 2px 2px 0 0;
    .exam-title {
      padding: 12px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 22px;
      color: #000000;
    }
    .icon {
      width: 6px;
      height: 6px;
      background: rgba(255, 80, 80, 0.39);
      border-radius: 50%;
    }
    .warning {
      width: 6px;
      height: 6px;
      top: 20px;
      background: #ff5050;
      border-radius: 50%;
      position: absolute;
    }
    .message {
      margin-right: 20px;
      padding-left: 9px;
      padding-top: 15px;
      position: relative;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #438bff;
      cursor: pointer;
    }
  }
}
</style>
